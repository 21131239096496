import React from 'react';
import meta4 from "../../images/meta4.png";
import meta5 from "../../images/meta5.png";

const Metatrader = () => {
  return (
    <div className=''>
        <div className='md:flex md:justify-center gap-3'>
            <p className='font-mono font-semibold text-xl md:mt-7'>Powered By:</p>
            <div className='md:flex flex flex-row justify-center gap-3'>
              <div className='flex'>
                <img className="w-16" src={meta4} alt="" />
                <p className='text-orange-500 font-semibold mt-4'>MetaTrader <span className='font-mono text-3xl'>4</span></p>
              </div>
                
                <span className='text-xl font-mono mt-7'>&</span>
              <div className='flex'>
                <img className="w-16" src={meta4} alt="" />
                <p className='text-orange-500 font-semibold mt-4'>MetaTrader <span className='font-mono text-3xl'>5</span></p>
              </div>
            </div>
            
        </div>
    </div>
  )
}

export default Metatrader;