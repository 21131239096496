// App.js
import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import Homex from "./pages/landingx/Homex";
import About from "./pages/landingx/Aboutx";
import Contact from "./pages/landingx/Contactx";
import Market from "./pages/landingx/Marketsx";
import Loginx from "./pages/landingx/Loginx";
import Registerx from "./pages/landingx/Registerx";
import Activate from "./pages/landingx/Activate";
import Dashboardx from "./pages/landingx/Dashboard/Dashboardx";
import Deposit from "./pages/landingx/Dashboard/Deposit";
import Withdraw from "./pages/landingx/Dashboard/Withdraw";
import Profile from "./pages/landingx/Dashboard/Profile";
import Withdrawal from "./pages/landingx/Adminx/Withdrawal";
import EditUsers from "./pages/landingx/Adminx/EditUsers";
import AllUsers from "./pages/landingx/Adminx/AllUsers";
import UserDep from "./pages/landingx/Adminx/UserDep";
import SetActivate from "./pages/landingx/SetActivate";
import ResetPassword from "./pages/landingx/ResetPassword";
import TC from "./pages/landingx/Dashboard/T&C";
import BtcWld from "./pages/landingx/Dashboard/BtcWld";
import UsdcWld from "./pages/landingx/Dashboard/UsdcWld";

// Loader Component
const Loader = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <Spin size="large" />
  </div>
);

const App = () => {
  const { email } = useSelector((state) => state.auth.user_details);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Start loading when location changes
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Show loader for 1 second

    return () => clearTimeout(timer); // Cleanup timer
  }, [location]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Routes>
          <Route path="/" element={<Homex />} />
          <Route path="/public/login" element={<Loginx />} />
          <Route path="/public/register" element={<Registerx />} />
          <Route path="/public/activate" element={<Activate />} />
          <Route path="/on-activate/:email" element={<SetActivate />} />
          <Route path="/user/dashboard" element={<Dashboardx />} />
          <Route path="/user/deposit" element={<Deposit />} />
          <Route path="/user/withdraw" element={<Withdraw />} />
          <Route path="/user/profile" element={<Profile />} />
          <Route path="/admin/users" element={<AllUsers />} />
          <Route path="/admin/withdrawals" element={<Withdrawal />} />
          <Route path="/admin/edituser" element={<EditUsers />} />
          <Route path="/admin/deposits" element={<UserDep />} />
          <Route path="/public/forgot-password" element={<ResetPassword />} />
          <Route path="/user/T&C" element={<TC />} />
          <Route path="/user/btc/withdrawal" element={<BtcWld />} />
          <Route path="/user/usdc/withdrawal" element={<UsdcWld />} />
          {/* Add other routes as needed */}
        </Routes>
      )}
    </div>
  );
};

export default App;
