import React, { useEffect, useState } from "react";
import AdminNav from "./AdminNav";
import User from "./Users";
import {  Modal, Form, Input, message, Spin } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AllUsers = () => {
  const notify = (word) => {
    toast(word);
    setReload((reload) => !reload);
  };
  const [reload, setReload] = useState(false);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  // const [incrementAmount, setIncrementAmount] = useState(""); // Default value for increment amount
  // const [interval, setInterval] = useState(""); // Default value for interval (1 hour in ms)


  useEffect(() => {
    fetch("https://admin.bultpay.org/users", {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log("usersssssssssss", res.users);
        setUsers(res.users);
      })
      .catch((err) => console.log("errrrrrrr", err));
  }, [reload]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [incrementModal, setIncrementModal] = useState(false);

  const [usdc, setUsdc] = useState(""); // Initialize usdc state
  const [loading, setLoading] = useState(false); // For loading state during submission

  const showModal = () => {
    setIsModalVisible(true);
  };

  // const showIncrementModal = () => {
  //   setIncrementModal(true);
  // };

  // const hideIncrementModal = () => {
  //   setIncrementModal(false);
  // }

  const handleCancel = () => {
    setIsModalVisible(false);
    // setUsdc(""); // Clear the input field when modal is closed
  };

  const handleSubmit = async () => {
    setLoading(true); // Set loading to true while waiting for response
    try {
      const response = await fetch('https://admin.bultpay.org/update-admin-usdc', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: "admin@bultpay.org", // Replace with actual admin email
          adminUsdc: usdc,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update wallet'); // Throw error for non-2xx responses
      }

      const data = await response.json(); // Parse the JSON response
      message.success('Wallet updated successfully!'); // Show success message
      setIsModalVisible(false); // Close the modal
      // setUsdc(""); // Clear the input field
      // console.log(data); // Optionally log the response
    } catch (error) {
      // console.error(error);
      message.error('Failed to update wallet.'); // Show error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  // const submitData = async () => {
  //  // Set loading to true while waiting for response
  //   const increment = Number(incrementAmount); // Ensure it's a number
  //   const intervalInHours = Number(interval); // Get interval input as a number

  //   // Make sure values are valid before sending
  //   if (isNaN(increment) || isNaN(intervalInHours) || intervalInHours <= 0) {
  //     setLoading(false);
  //     message.error('Please enter valid numeric values for increment and a positive interval in hours.');
  //     return;
  //   }

  //   // Convert interval from hours to milliseconds
  //   const intervalInMs = intervalInHours * 3600000; // 1 hour = 3600000 milliseconds

  //   try { setLoading(true); 
  //     const response = await fetch('https://admin.bultpay.org/update-settings', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         incrementAmount: increment,
  //         interval: intervalInMs, // Send the interval in milliseconds
  //       }),
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to update settings');
  //     }

  //     const data = await response.json();
  //     setLoading(false);
  //     message.success('Settings updated successfully!');
  //     setIncrementAmount(increment)
  //     // Optionally handle the data received from the server
  //   } catch (error) {
  //     message.error('Failed to update settings.');
  //   }
  // };

  return (
    <div className="px-0 lg:px-0 w-full">
      <div>
        <AdminNav />
        <div className="px-4 md:px-10 mx-auto w-full m-24">
          <div className="">
            <div>
              <div class="text-2xl font-semibold mb-4 text-center default_cursor_cs">
                All Users on the site
              </div>
              <ToastContainer />
              <input
                type="text"
                placeholder="search here..."
                class="mx-auto block py-2 rounded px-2 my-2"
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
              />

              <div className="flex justify-end px-3 pb-3">
                <button onClick={showModal} className="bg-blue-900 text-white font-sans text-xs px-4 py-3 uppercase shadow-black/80 rounded-md shadow-md hover:shadow-none hover:bg-blue-800 hover:transition hover:ease-in-out hover:duration-300 ease-out duration-300">Update MetaTrader Wallet Address</button>
              </div>
              {/* <div className="flex justify-end px-3">
                <button className="bg-blue-900 text-white font-sans text-xs px-4 py-3 uppercase shadow-black/80 rounded-md shadow-md hover:shadow-none hover:bg-blue-800 hover:transition hover:ease-in-out hover:duration-300 ease-out duration-300">Netprofit Incremental Value: <span></span></button>
              </div> */}

              {/* <Modal
                title="Netprofit Incremental Value"
                visible={incrementModal}
                onCancel={hideIncrementModal}
                onOk={submitData}
                okText="Save Input"
                cancelText="Cancel"
              >
                <Spin spinning={loading}>
                  <Form layout="vertical">
                    <Form.Item label="INCREMENT" required>
                      <Input
                        placeholder="Enter new increment amount"
                        value={incrementAmount} // Display current value
                        onChange={(e) => setIncrementAmount(e.target.value)} // Update on change
                        required
                      />
                    </Form.Item>
                    <Form.Item label="INTERVAL (in hours)" required>
                      <Input
                        placeholder="Enter interval in hours"
                        value={interval} // Display current value
                        onChange={(e) => setInterval(e.target.value)} // Update on change
                        required
                      />
                    </Form.Item>
                  </Form>    
                </Spin>
              </Modal> */}


              {/* Wallet Info Card */}
              {usdc && (
                <div className="max-w-md mx-auto bg-white shadow-md rounded-lg p-6 mt-6 font-sans">
                  <h2 className="text-xl font-bold">MetaTrader USDC Wallet Address</h2>
                  <p className="mt-2 text-gray-600 font-medium">Address: <span className="font-normal text-sm">{usdc}</span></p>
                </div>
              )}

              <Modal
                title="Add USDC Wallet for MetaTrader"
                visible={isModalVisible}
                onCancel={handleCancel}
                onOk={handleSubmit}
                okText="Save Wallet"
                cancelText="Cancel"
              >
                <Form layout="vertical">
                  <Form.Item label="Wallet Address" required>
                    <Input
                      placeholder="Enter your USDC wallet address"
                      value={usdc}
                      onChange={(e) => setUsdc(e.target.value)} // Update on change
                      required
                    />
                  </Form.Item>

                  {/* <Form.Item label="Wallet Label (Optional)">
                    <Input
                      placeholder="Wallet label (optional)"
                      value={walletLabel}
                      onChange={(e) => setWalletLabel(e.target.value)}
                    />
                  </Form.Item> */}
                </Form>
              </Modal>

              

              <div className="px-3 grid grid-cols-1 lg:grid-cols-2 mt-10 lg:gap-0 gap-6">
                {users
                  .filter((user) => {
                    if (search === "") {
                      return user;
                    } else if (
                      user.email.toLowerCase().includes(search.toLowerCase()) ||
                      user.name.toLowerCase().includes(search.toLowerCase()) ||
                      user.phone.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return user;
                    }
                  })
                  .map((user, i) => {
                    return <User key={i} user={user} notify={notify} />;
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllUsers;
