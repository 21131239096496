import React, { useEffect, useState } from "react";
import { Drawer, Modal } from "antd";
import bultpay from "../../../images/bultpay3.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDeposit } from "../../../Redux/action";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCheck, FaCopy } from "react-icons/fa"; // Assuming you're using react-icons for SVGs
import { MdCheckCircle, MdCancel } from "react-icons/md"; // Assuming you're using react-icons for SVGs


const Withdraw = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user_details);


  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const [method, setMethod] = useState("");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  
  const onChange = (e) => {
    const { value } = e.target;

    
      setMethod(value);
  };

  const [address, setAddress] = useState("")
  const [amount, setAmount] = useState(""); 
  const [metaTraderFee, setMetaTraderFee] = useState(0); // State for the calculated fee

  // Handler for amount input change
  const handleAmountChange = (e) => { 
    const value = parseFloat(e.target.value) || 0; // Parse value as float and default to 0 if NaN
    setAmount(value); // Update the amount 

    // Calculate the MetaTrader fee (0.8%)
    const fee = (value * 0.08).toFixed(2); // Calculate fee and format it to 2 decimal places
    setMetaTraderFee(fee); // Update the fee state 
  }; 

  const totalAmount = (parseFloat(amount) || 0) + (parseFloat(metaTraderFee) || 0); // Ensure proper number addition



    const notify = (word) => {
      toast.info(`${word}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };

  // const onSubmit = () => {
  //   dispatch(setDeposit(amt));
  //   if (!method) {
  //     return notify("Select Method");
  //   }
    
  //   if (method == "btc") {
  //   // navigate("/user/btc/withdrawal", { replace: true });        
  //   } else if (method == "Bank") { 
  //   // navigate("/user/usdc/withdrawal")
  //   } else if (method == "Paypal") {
  //     //navigate to btc withdrawal page
  //   }
  // }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);

  const showModal = () => {
    if (!amount || !address) {
      return notify("Enter required field");
    } else {
      setIsModalOpen(true);
    }
  };

  const handleProceed = () => {
    handleCancel(); // Close the initial modal
    setWarningModalOpen(true); // Open the warning modal
  };

  const handleWarningClose = () => {
    setWarningModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    
    // Reset the icon back to the copy icon after a delay (e.g., 2 seconds)
    setTimeout(() => setCopied(false), 3000);
  };

  const [adminUsdc, setAdminUsdc] = useState("");
  
  useEffect(() => {
    fetch("https://admin.bultpay.org/get-profile", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: "admin@bultpay.org",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log("ttt", res);
        const { user } = res;

        // Make sure the 'user' object contains 'UserBtc'
        // console.log("Profile Data", user);

        if (user.blocked) {
          navigate("/user/blocked", { replace: true });
        }

        if (!user) return;
        setAdminUsdc(user.adminUsdc);
      })
      .catch((err) => console.log("error", err));
  }, []);


  return (
    <div>
      {/* Drawer */}
      <Drawer
        placement={placement}
        closable={false}
        open={open}
        key={placement}
      >
        <div className="w-[93%] pt-6 pl-2.5 default_cursor_cs">
          <p className="flex justify-between py-2">
            <Link to="/user/dashboard">
              <img
                src={bultpay}
                className="w-28 lg:w-36 text-xl font-semibold left-0 whitespace-nowrap text-white"
              />
            </Link>
            
            <svg
              onClick={onClose}
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
              class="default_cursor_cs"
            >
              <path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z"></path>
            </svg>
          </p>
        </div>
        <div class="flex items-center justify-between border-y-2 my-2 py-6 px-4">
          <p class="rounded-full w-8 h-8 flex justify-center items-center bg-sky-600 mr-6">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 24 24"
              class=" text-gray-300 bg-clip-text rounded-full"
              height="13"
              width="13"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M20 22h-2v-2a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v2H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"></path>
              </g>
            </svg>
          </p>
          <p class="flex flex-col flex-1 text-sm">
            <span class="font-semibold"></span>
            <span class="text-slate-600 text-xs"></span>
          </p>
          
        </div>
        <div className="w-11/12 font-medium text-slate-700 text-sm">
          <div class="tracking mt-10 uppercase text-xs font-bold pl-3 tracking-wider">
            Menu
          </div>
          <ul>
            <Link
              class="flex text-black hover:bg-slate-200 items-center py-3 px-3 rounded w-10/12  active"
              to="/user/dashboard"
              style={{ color: "rgb(225, 29, 72)" }}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 16 16"
                class="mr-4"
                height="25"
                width="25"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h2A1.5 1.5 0 0 1 5 1.5v2A1.5 1.5 0 0 1 3.5 5h-2A1.5 1.5 0 0 1 0 3.5v-2zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-2zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2H1zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h14zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"></path>
              </svg>
              <span>Dashboard</span>
            </Link>
            {/* <Link
              class="flex text-black hover:bg-slate-200 items-center py-3 px-3 rounded w-10/12"
              to="/user/deposit"
              style={{ color: "rgb(82, 100, 132)" }}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                class="mr-4"
                height="25"
                width="25"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12.414 5H21a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.414l2 2zM4 5v14h16V7h-8.414l-2-2H4zm8 7V9l4 4-4 4v-3H8v-2h4z"></path>
                </g>
              </svg>
              <span>Deposit</span>
            </Link> */}
            <Link
              class="flex text-black hover:bg-slate-200 items-center py-3 px-3 rounded w-10/12"
              to="/user/withdraw"
              style={{ color: "rgb(82, 100, 132)" }}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                class="mr-4"
                height="25"
                width="25"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12.414 5H21a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.414l2 2zM4 5v14h16V7h-8.414l-2-2H4zm8 7V9l4 4-4 4v-3H8v-2h4z"></path>
                </g>
              </svg>
              <span>Withdraw</span>
            </Link>
            <Link
              class="flex text-black hover:bg-slate-200 items-center py-3 px-3 rounded w-10/12"
              to="/user/profile"
              style={{ color: "rgb(82, 100, 132)" }}
            >
              <svg
                stroke="currentColor"
                fill="none"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="mr-4"
                height="25"
                width="25"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="8.5" cy="7" r="4"></circle>
                <polyline points="17 11 19 13 23 9"></polyline>
              </svg>
              <span>Account Setting</span>
            </Link>
          </ul>
        </div>
      </Drawer>

      {/* content */}
      <div className="px-0 lg:px-0 w-full">
        <div className="md:flex relative">
          {/* Sidebar  */}
          <div
        className={`${
          isOpen ? 'md:w-64' : 'md:w-20'
        }  hidden md:block md:bg-gray-200 md:p-5 md:pt-8 md:relative md:duration-300 `}
      >
        <button
          onClick={toggleSidebar}
          className="absolute -right-3 top-9 w-7 h-7 bg-white rounded-full border-2 border-blue-900 text-blue-900 flex items-center justify-center"
        >
          {isOpen ? '<' : '>'}
        </button>

        <div className="flex items-center gap-x-4">
          <Link to="/user/dashboard" className="text-blue-900 font-extrabold text-xl">
            {isOpen ? <img src={bultpay} alt="" /> : 'BP'}
          </Link>
        </div>

        <div className="flex items-center justify-between border-y-2 my-2 py-6 px-4 md:hidden">
          <p class="rounded-full w-8 h-8 flex justify-center items-center bg-blue-600 mr-6">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 24 24"
              class=" text-gray-300 bg-clip-text rounded-full"
              height="13"
              width="13"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M20 22h-2v-2a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v2H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"></path>
              </g>
            </svg>
          </p>
          <p class="flex flex-col flex-1 text-sm">
            <span class="font-semibold">$</span>
            <span class="text-gray-600 text-xs">$</span>
          </p>
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 24 24"
            height="20"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0V0z"></path>
            <path d="M7 10l5 5 5-5H7z"></path>
          </svg>
        </div>
        <div className="py-6 px-1 mb-5 w-11/12 hidden md:block">
          <div className={`uppercase mt-3  text-xs tracking-wide text-gray-700 font-semibold ${
            !isOpen && 'hidden'
          }`}>
            Main account Balance
          </div>
          <p class="text-2xl font-semibold text-blue-800">
            ${user.balance} <span class="text-lg">USD</span>
          </p>
          {/* <p className={`flex justify-between text-gray-800 mt-3 font-medium text-sm ${
            !isOpen && 'hidden'
          }`}>
            <span>Deposit</span>
            <span>${user.deposit} usd</span>
          </p> */}
          <p className={`flex justify-between text-gray-800 mt-3 font-medium text-sm ${
            !isOpen && 'hidden'
          }`}>
            <span>Withdraw</span>
            <span>${user.withdrawal} usd</span>
          </p>
          <p class={`flex justify-bentween gap-3 items-center font-semibold pt-5 ${
            !isOpen && 'hidden'
          }`}>
            {/* <Link
              class="flex items-center pl-4 pr-5 py-1.5 hover:text-white text-sm bg-blue-800 text-white rounded"
              to="/user/deposit"
            >
              <span>Deposit</span>
            </Link> */}
            <Link
              className="px-4 py-1.5 w-full flex justify-center text-center text-sm bg-blue-200 hover:text-gray-800 text-gray-600 rounded"
              to="/user/withdraw"
            >
              Withdraw
            </Link>
          </p>
        </div>

        {/* Navigation */}
        <ul className="pt-6">
          <Link
            to="/user/dashboard"
            className={`flex items-center gap-x-4 p-2 mt-5 text-gray-800 hover:bg-blue-200 rounded-md cursor-pointer ${
              !isOpen && 'justify-center p-0.5 my-4'
            }`}
          >
            <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 16 16"
                class="mr-4"
                height="25"
                width="25"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h2A1.5 1.5 0 0 1 5 1.5v2A1.5 1.5 0 0 1 3.5 5h-2A1.5 1.5 0 0 1 0 3.5v-2zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-2zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2H1zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h14zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"></path>
              </svg>
            {isOpen && <span>Dashboard</span>}
          </Link>

          {/* <Link
            to="/user/deposit"
            className={`flex items-center gap-x-4 p-2 my-5 text-gray-800 hover:bg-blue-200 rounded-md cursor-pointer ${
              !isOpen && 'justify-center p-0.5 my-4'
            }`}
          >
            <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                class="mr-4"
                height="25"
                width="25"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12.414 5H21a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.414l2 2zM4 5v14h16V7h-8.414l-2-2H4zm8 7V9l4 4-4 4v-3H8v-2h4z"></path>
                </g>
              </svg>
            {isOpen && <span>Deposit</span>}
          </Link> */}

          <Link
            to="/user/withdraw"
            className={`flex items-center gap-x-4 p-2 my-5 text-gray-800 hover:bg-blue-200 rounded-md cursor-pointer ${
              !isOpen && 'justify-center p-0.5 my-4'
            }`}
          >
            <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                class="mr-4"
                height="25"
                width="25"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12.414 5H21a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.414l2 2zM4 5v14h16V7h-8.414l-2-2H4zm8 7V9l4 4-4 4v-3H8v-2h4z"></path>
                </g>
              </svg>
            {isOpen && <span>Withdraw</span>}
          </Link>

          <Link
            to="/user/profile"
            className={`flex items-center gap-x-4 p-2 my-5 text-gray-800 hover:bg-blue-200 rounded-md cursor-pointer ${
              !isOpen && 'justify-center p-0.5 my-4'
            }`}
          >
            <svg
                stroke="currentColor"
                fill="none"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="mr-4"
                height="25"
                width="25"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="8.5" cy="7" r="4"></circle>
                <polyline points="17 11 19 13 23 9"></polyline>
              </svg>
            {isOpen && <span>Account Settings</span>}
          </Link>

          {/* <li
            className={`flex items-center gap-x-4 p-2 text-gray-800 hover:bg-blue-300 rounded-md cursor-pointer ${
              !isOpen && 'justify-center'
            }`}
          >
            <i className="fas fa-question-circle"></i>
            {isOpen && <span>Help</span>}
          </li> */}
        </ul>
      </div>
          {/* withdraw content */}
          <div className="flex-1 md:h-full h-screen bg-[#f5f6fa]">
            <div className="pt-2 px-3 flex items-center justify-between border-b border-gray-200 bg-white">
              <div class="bg-green200 p-0 relative -top-1" onClick={showDrawer}>
                <span tabindex="0" class="btn btn-ghost btn-circle  md:hidden">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 e text-black"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h7"
                    ></path>
                  </svg>
                </span>
              </div>
              <div class="justify-self-center">
                <Link aria-current="page" class="active" to="/user/dashboard">
                  <img
                    src={bultpay}
                    class="w-28 p-3 lg:w-36 bg-white self-center text-xl font-semibold whitespace-nowrap text-white mr-12"
                  />
                </Link>
              </div>
              <ToastContainer />
              <Link to="/user/profile" class="py-1">
                <p class="rounded-full w-8 h-8 flex justify-center items-center bg-rose-600">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 24 24"
              class=" text-gray-300 bg-clip-text rounded-full"
              height="13"
              width="13"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M20 22h-2v-2a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v2H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"></path>
              </g>
            </svg>
                </p>
              </Link>
            </div>

            <section className="bg-[#f5f6fa] md:flex md:my-20 flex-col justify-center font-normal">
              <div className="flex flex-col items-center font-semibold text-2xl px-0 md:px-6 pt-8 h-full">
                <p className="text-lg md:text-3xl text-slate-600 text-center mt-6">
                  Withdraw Funds
                </p>
                <p className="text-center font-normal text-sm px-2 md:w-1/3 text-slate-600">
                  Secure and safely withdraw money into your account
                </p>
                <section className=" w-full md:w-auto">
                  <div className="h-full w-full flex font-normal flex-col items-center">
                    <div className=" flex flex-col justify-center w-11/12 md:w-full mt-10">
                      <div className="mb-6 flex flex-col justify-center">
                        <label className="text-sm pb-2 font-semibold">
                          Select withdrawal method:
                        </label>
                        <select onChange={onChange} class="py-1.5 rounded border mt-1 border-gray-200 w-full  text-base">
                          <option value="">Select method</option>
                          <option value="btc">Bitcoin</option>
                          <option value="eth">Ethereum</option>
                          <option value="usdc">USDC</option>
                          {/* <option value="xrp">Ripple </option>
                          <option value="doge">Dogecoin</option>
                          <option value="dot">Polkadot </option>
                          <option value="shib">SHIBA INU </option>
                          <option value="Paypal">Paypal</option> */}
                        </select>
                      </div>

                      <p className="flex flex-col w-72 md:w-80 lg:w-96 text-base">
                        <span className="text-sm pb-2 font-semibold">Enter Address:</span>
                        <input
                          type="text/number"
                          name="address"
                          placeholder="Enter Address"
                          value={address}  // Bind the address state to the input value
                          onChange={(e) => setAddress(e.target.value)}  // Update state when input changes
                          className="py-1 px-2 text-base rounded border mt-1 mb-4 border-gray-200 md:w-96"
                        />
                      </p>
                      <p className="flex flex-col w-72 md:w-80 lg:w-96 text-base">
                        <span className="text-sm pb-2 font-semibold">Enter Amount:</span>
                        <input
                          type="text/number"
                          name="amount"
                          // placeholder="0"
                          value={amount}
                          onChange={handleAmountChange} // Handle input change
                          className="py-1 px-2 text-base rounded border mt-1 mb-4 border-gray-200 md:w-96"
                        />
                      </p>

                      <p className="flex flex-col w-72 md:w-80 lg:w-96 text-base">
                        <span className="text-sm pb-2 font-semibold">MetaTrader Fee:</span>
                        <input
                          type="text"
                          name="metaTrader fee"
                          placeholder="0"
                          value={metaTraderFee} // Display the fee, formatted to 2 decimal places
                          readOnly // Make it read-only since it's calculated
                          className="py-1 px-2 text-base rounded border mt-1 mb-4 border-gray-200 md:w-96"
                        />
                      </p>

                      {/* <p className="flex flex-col w-72 md:w-80 lg:w-96 text-base">
                        <span className="text-sm pb-2 font-semibold">Total Amount:</span>
                        <input
                          type="text"
                          value={totalAmount.toFixed(2)} // Display the total amount, formatted to 2 decimal places
                          readOnly // Make it read-only since it's calculated
                          className="py-1 px-2 text-base rounded border mt-1 mb-4 border-gray-200 md:w-96"
                        />
                      </p> */}

                      <p className="flex flex-col w-72 md:w-80 lg:w-96 text-base">
                        <span class="text-sm pb-2 font-semibold">
                          Description (Optional):
                        </span>
                        <textarea
                          type="text"
                          class="py-1 px-2 text-base rounded border mt-1 mb-4 border-gray-200 md:w-96"
                        />
                      </p>
                      <button onClick={showModal} className="px-3 py-1.5 md:w-72_ md:w-80 lg:w-full md:py-2.5 text-sm bg-red-500 text-white font-medium rounded uppercase mt-3 md:mt-0">
                        Continue to pay
                      </button>
                    </div>
                  </div>

                  <Modal
                  title="Pay MetaTrader Fee"
                  open={isModalOpen}
                  onOk={handleProceed}
                  onCancel={handleCancel}
                  okText="Proceed" // Change OK button text to "Proceed"
                >
                  <div className="border border-gray-100 w-full"></div>
                  <div className="text-black">
                    <section className="border mt-5 border-slate-300 px-1 md:px-4 mb-6 font-normal default_cursor_cs">
                      <div class="flex justify-between items-center border-b py-2 border-slate-300 text-sm font-normal default_cursor_cs">
                        <span>Payment method</span>
                        <span class="flex uppercase text-sm font-normal default_cursor_cs">
                          {" "}
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            class=" text-slate-800 rounded-full mr-2"
                            height="17"
                            width="17"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M47.5 104H432V51.52a16 16 0 00-19.14-15.69l-368 60.48a16 16 0 00-12 10.47A39.69 39.69 0 0147.5 104zm416 24h-416a16 16 0 00-16 16v288a16 16 0 0016 16h416a16 16 0 0016-16V144a16 16 0 00-16-16zM368 320a32 32 0 1132-32 32 32 0 01-32 32z"></path>
                            <path d="M31.33 259.5V116c0-12.33 5.72-18.48 15.42-20 35.2-5.53 108.58-8.5 108.58-8.5s-8.33 16-27.33 16V128c18.5 0 31.33 23.5 31.33 23.5L84.83 236z"></path>
                          </svg>
                          USDC
                        </span>
                      </div>
                      <div class="flex justify-between items-center border-b py-2 border-slate-300 text-sm default_cursor_cs">
                        <span class="flex text-sm">{adminUsdc.slice(0, 6)}...</span>
                        <CopyToClipboard text={adminUsdc} onCopy={handleCopy}>
                          {copied ? (
                            <MdCheckCircle 
                              class="mt-1 ml-4 text-green-500 bg-pr default_cursor_cs"
                              size={20}
                            />
                          ) : (
                            <FaCopy
                              class="mt-1 ml-4 text-green-500 hover:text-green-600 bg-pr default_cursor_cs"
                              size={20}
                            />
                          )}
                        </CopyToClipboard>
                      </div>
                      <div class="flex justify-between items-center border-b py-2 border-slate-300 text-sm default_cursor_cs">
                        <span>You will pay</span>
                        <span class="uppercase font-medium default_cursor_cs">
                          ${metaTraderFee}
                        </span>
                      </div>
                      <div class="mb-3 pt-3 text-sm default_cursor_cs">
                        *Click the copy icon and proceed to make payment.
                      </div>
                      <div class="mb-3 text-sm default_cursor_cs">
                        *Withdrawal will be completed once fee is received.
                      </div>
                    </section>
                    {/* <button
                      class="px-3 mt-8 pt-1.5 pb-1.5 w-full md:py-2.5 text-xs bg-yellow-500 text-white font-medium rounded uppercase md:mt-0 default_pointer_cs"
                    >
                      Confirm
                    </button> */}
                  </div>
                </Modal>

                 {/* Warning Modal */}
                <Modal
                  title="⚠️ Warning"
                  open={isWarningModalOpen}
                  onOk={handleWarningClose}
                  onCancel={handleWarningClose}
                  okText="OK"
                  okButtonProps={{ style: { color: "#ffffff", backgroundColor: "#007bff" } }}
                  
                >
                  <div className="flex flex-col gap-y-3 justify-center items-center text-center">
                    <MdCancel className="text-red-500" size={56}/>
                    <p className="text-lg">Please complete payment before proceeding.</p>
                  </div>
                </Modal>
                </section>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
