import React from 'react';

const Widget = () => {
  const widgetHTML = `
    <iframe src="https://fxpricing.com/fx-widget/ticker-tape-widget.php?id=1,2,3,5,14,20,18,19,20,21,24,27&border=show&speed=50&click_target=blank&theme=dark&tm-cr=212529&hr-cr=FFFFFF13&by-cr=28A745&sl-cr=DC3545&flags=circle&d_mode=compact-name&column=ask,bid,spread&lang=en&font=Arial, sans-serif" 
    width="100%" height="50" style="border: unset;"></iframe>
    <div id="fx-pricing-widget-copyright">
      <span>Powered by </span>
      <a href="https://fxpricing.com/" target="_blank">FX Pricing</a>
    </div>
    <style type="text/css">
      #fx-pricing-widget-copyright {
        text-align: center;
        font-size: 13px;
        font-family: sans-serif;
        margin-top: 10px;
        margin-bottom: 10px;
        color: #9db2bd;
      }
      #fx-pricing-widget-copyright a {
        text-decoration: unset;
        color: #bb3534;
        font-weight: 600;
      }
    </style>
  `;

  return (
    <div dangerouslySetInnerHTML={{ __html: widgetHTML }} />
  );
};

export default Widget;




// const Widget = () => {
//   return (
//     <coingecko-coin-price-marquee-widget
//       coin-ids="bitcoin,ethereum,eos,ripple,litecoin"
//       currency="usd"
//       background-color="#ffffff"
//       locale="en"
//     ></coingecko-coin-price-marquee-widget>
//   );
// };

// export default Widget;
